import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer } from 'react-toastify';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import * as moment from "moment-timezone";
import Login from '../common/login';
const columns = [

    { field: "count", name: "العدد ", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المنتج", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            pages: [],
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,

        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    componentDidMount() {
        let header = { "Content-Type": "application/json", token: cookies.get("token") };


        axios.get(host + `users/pages/name/all`, { headers: header })
            .then(res => {

                this.setState({

                    pages: res.data.data.pages,
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    Orders(value) {
        var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('DD/MM/YYYY');
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/printed/orders/reports?from=${date1}&to=${date2}&page=${value}`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.data.items
                console.log(res.data.data);

                for (let i = 0; i < items.length; i++) {
                    let obj = {
                        id: i,
                        name: items[i].components_name,
                        count: items[i].count,

                    };
                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false,
                    total_items: res.data.data.items_sum,
                    total_orders: res.data.data.order_count,
                    items_cost: this.numberWithCommas(res.data.data.items_cost),
                    orders_cost: this.numberWithCommas(res.data.data.sells),
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>

                                </div>

                                <div id='ContinerReportDatePages'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <div><span>من : &nbsp;</span></div>
                                            <div>   <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            /></div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <div><span>الى : &nbsp;</span></div>
                                            <div>   <DatePicker id='date2'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            /></div>
                                        </Col>


                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <select className="selectPages" id="selectPage" >
                                                <option value={'All'}>كل الصفحات</option>
                                                {this.state.pages.map((item, i) =>
                                                    <option value={item.id}>{item.name}</option>

                                                )}

                                            </select >
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })

                                                let select = document.getElementById('selectPage')
                                                this.setState({ page: select.options[select.selectedIndex].text })
                                                // this.componentDidMount(select.value)
                                                this.Orders(select.value)
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>


                                {!this.state.serch ? null : (
                                    <div>
                                        <div className='DataTableContiner'>
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                            </MuiThemeProvider>
                                        </div>
                                        <div id='ContinerReportDate1'>
                                            <div id='AddClientDeptBTN1'>مجموع الطلبات</div>
                                            <div style={{ marginRight: 20 }}>{this.state.total_orders}</div>
                                        </div>
                                        <br />
                                        <div id='ContinerReportDate1'>
                                            <div id='AddClientDeptBTN12'>مجموع القطع</div>
                                            <div style={{ marginRight: 20 }}>{this.state.total_items}</div>
                                        </div>

                                        <div id='ContinerReportDate1'>
                                            <div id='AddClientDeptBTN13'>اسعار المواد</div>
                                            <div style={{ marginRight: 20 }}>{this.state.items_cost}</div>
                                        </div>

                                        <div id='ContinerReportDate1'>
                                            <div id='AddClientDeptBTN14'>مجموع المبيعات</div>
                                            <div style={{ marginRight: 20 }}>{this.state.orders_cost}</div>
                                        </div>
                                    </div>

                                )}

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;